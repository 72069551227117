<template>
  <div class="service service--gpdr gdpr">
    <div class="content">
      <header>
        <h3>{{ getContent('TEXT_DATA_PROTECTION_HEADLINE_1', true) }}</h3>
      </header>
      <div class="content-block" v-html="getContent('TEXT_DATA_PROTECTION_CONTENT_1', false)"></div>
      <h4>{{ getContent('TEXT_DATA_PROTECTION_HEADLINE_2', true) }}</h4>
      <div class="content-block" v-html="getContent('TEXT_DATA_PROTECTION_CONTENT_2', false)"></div>
      <h4>{{ getContent('TEXT_DATA_PROTECTION_HEADLINE_3', true) }}</h4>
      <div class="content-block" v-html="getContent('TEXT_DATA_PROTECTION_CONTENT_3', false)"></div>
    </div>
  </div>  
</template>

<script>

import { ContentController } from "@/controller";

export default {
  name: "Gdpr",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  props: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    } 
  }
};
</script>